;(function( window, document, $, undefined ) {
  'use strict';

  var app = (function() {

    var $private = {};
    var $public = {};
    var screen_width = $(window).width();

    //Menu fixo
    $public.fixedMenu = function() {
      var menuPosition = $('.dd-section-menu').offset().top;

      $(window).on('scroll', function(){
        if($(this).scrollTop() >= menuPosition) {
          $('.dd-wrap-menu').addClass('dd-menu-fixed');
        }else {
          $('.dd-wrap-menu').removeClass('dd-menu-fixed');
        }
      });

    };

    //Menu responsivo
    $public.responsiveMenu = function() {
      $('.dd-menu').slicknav({
        prependTo : '.dd-responsive-menu',
        label : ''
      });
    };

    //Sub-menus
    $public.subMenu = function() {
      if (screen_width > 768) {
        $('.dd-has-sub').hover(function() {
          $(this).find('.dd-sub').stop(true, true).fadeToggle('fast');
        });
      }
    }

    //Compartilhar
    $public.share = function(){
      $('.dd-share').on("click", function (e) {
        e.preventDefault();
        var t = 640, n = 300, r = screen.width / 2 - t / 2, i = screen.height / 2 - n / 2;
        window.open($(this).attr("href"), "popup", "width=" + t + ",height=" + n + ",top=" + i + ",left=" + r);
      });
    }

    //Envia formulário automaticamente ao selecionar a opção
    $public.sendForm = function(){
      $('.dd-trigger-form').change(function(){
        $('form').submit();
      });
    }

    $public.toggle = function(){
      $('.dd-toggle-trigger').click(function(){
        $(this).next().stop().slideToggle();
      });
    }


    $public.imageCK = function(){
      $('a.img').each(function(){
        var that = $(this);
        var img = that.find('img');

        if (img.length > 0) {
          var f = img.css('float');

          if (f == 'right') {
            that.addClass('dd-img-ck-right');
          } else if (f == 'left') {
            that.addClass('dd-img-ck-left');
          } else {
            that.addClass('dd-img-ck-centered');
          }
        }
      });
    }

    //Slider padrão com thumbs de paginação
    $public.defaultSlider = function(){
      $('.dd-default-slider').bxSlider({
        minSlides: 1,
        maxSlides: 1,
        pager: true,
        controls: true,
        infiniteLoop: false,
        hideControlOnEnd : true,
        pagerCustom: '.dd-thumb-slide',
        onSliderLoad : function() {
          $('.dd-default-slider').css('visibility' , 'visible');

          if($(this).find('li').length == 1) {
            $(this).parent().next().hide();
          }
        }
      });
    }

    //Thumbs slide
    $public.thumbSlide = function(data) {
      // Criando propriedades
      var sliderW;
      var slideMin;
      var slideMax;
      var slideMargin;

      // Verificando o slider a ser carregado
      if (data == 'exposition') {
        //Exposição
        sliderW = 80;
        slideMin = 1;
        slideMax = 7;
        slideMargin = 7;
      } else if(data == 'milestones') {
        //Marcos da cut
        sliderW = 65;
        slideMin = 2;
        slideMax = 11;
        slideMargin = 20;
      } else if(data == 'knowcut') {
        //Conheça a cut
        sliderW = 215;
        slideMin = 2;
        slideMax = 4;
        slideMargin = 20;
      }

      // Chamando bxSlider e setando as configs
      $('.dd-thumb-slide').bxSlider({
        slideWidth: sliderW,
        minSlides: slideMin,
        maxSlides: slideMax,
        slideMargin: slideMargin,
        pager: false,
        infiniteLoop : false,
        hideControlOnEnd : true,
        onSliderLoad : function() {

          $('.dd-thumb-slide').css('visibility' , 'visible');
          var sliderCount = $(this).find('li').length;

          if(screen_width >= 769 && sliderCount <= slideMax) {
            $(this).parent().next().hide();
          }
        }
      });
    }

    $public.lightboxLanguage = function(){
      var hasLightbox = false;

      $('a').each(function(){
        var that = $(this).attr('data-lightbox');

        if (that) {
          lightbox.option({
            'albumLabel' : 'Imagem %1 de %2'
          });
        }
      });
    }

    $public.resolucoesCongressos = function(){

      var element = $('#resolucoes_congressos_list');
      if (element) {

        var uri = element.attr('data-uri');
        $.get( uri, function( data ) {
          element.hide();
          element.html( data );
          element.slideDown();
        });

      }

    }

    $public.sliderSmall = function() {
      $('.dd-slide-small').bxSlider({
        controls: false,
        pager: true,
        auto: true,
        pause: 5000,
        onSliderLoad: function(){
          $(".dd-slide-content-small").removeClass('dd-banner-initial');
          $(".dd-slide-content-small").css("visibility", "visible");
        }
      });
    };

    return $public;
  })();

  // Global
  window.app = app;
  app.fixedMenu();
  app.responsiveMenu();
  app.subMenu();
  app.share();
  app.sendForm();
  app.toggle();
  app.imageCK();
  app.resolucoesCongressos();
  app.sliderSmall();

})( window, document, jQuery );

$(window).load(function() {
    app.lightboxLanguage();
});
